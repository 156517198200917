import Routes from "routes/routes";
import BobContextProvider, {UseBob} from "contexts/bob-context";

function App() {
  return (
    <div className="appContainer">
      <BobContextProvider>
        <Routes/>
      </BobContextProvider>
    </div>
  );
}

export default App;
