import BillyBobImg from "assets/images/billy-bob.png";
import NinjaBobImg from "assets/images/ninja-bob.png";
import UncleBobImg from "assets/images/uncle-bob.png";
import Gerald from "assets/images/gerald.png";
import CharacterTypes from "constants/character-types";
import Character from "classes/character";
import ComponentProps from "classes/component-props";
import "./character-hud.scss"

export interface PlayerHUDProps extends ComponentProps {
    character: Character
}

const CharacterHUD = (props: PlayerHUDProps) => {
    
    const { character } = props;

    return (
        <div className={`playerHudContainer ${props.className}`}>
            <div className="profile">
                {(()=>{
                    switch(character.name){
                        case CharacterTypes.Bob:
                            return <img className="bobProfile" src={BillyBobImg} alt="Bob"/>
                        case CharacterTypes.BillyBob:
                            return <img className="bobProfile" src={BillyBobImg} alt="Billy Bob"/>
                        case CharacterTypes.NinjaBob:
                            return <img className="bobProfile" src={NinjaBobImg} alt="Ninja Bob"/>
                        case CharacterTypes.UncleBob:
                            return <img className="bobProfile" src={UncleBobImg} alt="Uncle Bob"/>
                        case CharacterTypes.Gerald:
                            return <img className="bobProfile" src={Gerald} alt="Gerald"/>
                        default:
                            return <img className="bobProfile" src={BillyBobImg} alt="Bob"/>
                    }
                })()}
            </div>
            <span className="bobStat">Name: {character.name}</span>
            <span className="bobStat">Health: {character.health}</span>
            <span className="bobStat">Damage Per Attack: {character.attackDamage}</span>
            
        </div>
    )
}

export default CharacterHUD;