import { useState } from 'react';
import { UseBob, UseUpdateBob, BobActionType, BobAction } from "contexts/bob-context";
import Character from "classes/character";
import ComponentProps from "classes/component-props";
import CharacterHUD from 'components/character-hud/character-hud';
import "./battle.scss"

export interface BattleProps extends ComponentProps {
    who: Character,
    onResult: Function,
}

const Battle = (props: BattleProps) => {

    const {who: enemy, onResult} = props;
    const [battleStats, setBattleStats] = useState({turn: 0, bobDamage: 0, enemyDamage: 0});
    const bob = UseBob();
    const updateBob = UseUpdateBob();
    
    const attack = () => {
        if(bob.alive && enemy.alive){
            let newBattleStats = {...battleStats}
            newBattleStats.turn++
            newBattleStats.enemyDamage = bob.attack(enemy);
            if(!enemy.alive){
                bob.restore();
                updateBob(new BobAction(BobActionType.SetBob, bob));
                alert("You won the battle");
                onResult();
                return
            }
            newBattleStats.bobDamage = enemy.attack(bob);
            updateBob(new BobAction(BobActionType.SetBob, bob));
            setBattleStats(newBattleStats)
            if(!bob.alive){
                alert("You died, refresh to restart.");
            }
        }
    }

    return (
        <div className={`battleContainer ${props.className}`}>
            <CharacterHUD className="playerHud" character={bob}/>
            <div className="battleStatContainer">
                {battleStats.turn > 0 &&
                    <span className="bobDamage">Turn: {battleStats.turn} </span>
                }
                <button className="attackButton" onClick={attack}>Attack {enemy.name}!!</button>  
                {battleStats.turn > 0 &&
                    <>
                        {battleStats.bobDamage > 0 ?
                            <span className="bobDamage">You took {battleStats.bobDamage} damage</span>
                            :
                            <span className="bobDamage">You took no damage</span>
                        }
                        {battleStats.enemyDamage > 0 ?
                            <span className="enemyDamage">{enemy.name} took {battleStats.enemyDamage} damage.</span>
                            :
                            <span className="enemyDamage">{enemy.name} took no damage.</span>
                        }
                    </>
                }
            </div>
            <CharacterHUD className="enemyHud" character={enemy}/>
        </div>
    )
}

export default Battle;

