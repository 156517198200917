import Character from "../character";
import CharacterTypes from "constants/character-types";

class UncleBob extends Character {

    constructor(){
        super();
        this._name = CharacterTypes.UncleBob;
        this._techSkill = 100;
    }

}

export default UncleBob;