import Character from "./character";
import CharacterTypes from "constants/character-types";

class Gerald extends Character {

    constructor(){
        super();
        this._name = CharacterTypes.Gerald;
    }

}

export default Gerald;