import React, {useContext, useReducer} from "react";
import ComponentProps from "classes/component-props";
import Character from "../classes/character";

const BobContext = React.createContext<Character>(new Character());
const BobUpdate = React.createContext<React.Dispatch<BobAction> | Function>(()=>{});

export enum BobActionType {
    SetBob
}

export class BobAction {
    type: BobActionType;
    payload: any;

    constructor(type: BobActionType, payload: any) {
        this.type = type;
        this.payload= payload;
    }
}

function bobReducer(state: Character, action: BobAction): Character {
    switch (action.type){
        case BobActionType.SetBob:
            return action.payload;
        default:
            return state;
    }
}

export default function BobContextProvider(props: ComponentProps) {

    const [state, dispatch] = useReducer(bobReducer, new Character())

    return (
        <BobContext.Provider value={state}>
            <BobUpdate.Provider value={dispatch}>
                {props.children}
            </BobUpdate.Provider>
        </BobContext.Provider>
    )

}

export function UseBob() {
    return useContext(BobContext);
}

export function UseUpdateBob() {
    return useContext(BobUpdate);
}