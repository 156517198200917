import Chapter, { Section, SectionAction } from "classes/chapter";
import Gerald from "classes/gerald";

const Sections: Section[] = [
    new Section(
        "intro",
        "Welcome to Bob's world, where you get to choose the way you Bob.",
        [
            "This is Bob's world. As said before, you get to choose the way you Bob. Now, for this story, you need information on how to play: You read the paragraphs on the page and when you get to the end, choose an action by clicking the button. Therefore, you now know how to play the game.", 
            "(Good luck!)"
        ],
        new Array<SectionAction>(
            new SectionAction("Get Started", "Start")
        )
    ),
    new Section(
        "Start",
        "MORNING OF DAY 1",
        [
            "You wake up to see your dog, Wiggles, barking at you. He floats in the air. It isn't really a dog anymore, is it? Then your mother walks into the room, wearing that ridiculous apron saying 'I love my sneeze-cakes'.", 
            "'How was your night, honey?' she says. What do you say?"
        ],
        new Array<SectionAction>(
            new SectionAction("Respond: Horrible", "sleptHorrible"),
            new SectionAction("Respond: Fantastic", "sleptFantastic"),
        )
    ),

    new Section(
        "sleptHorrible",
        "YOU SLEPT HORRIBLY",
        [
            "'It was horrible,' You say. You draw your attention back to the floating 'dog'.", 
            "'Aww, sorry,' She respondes back. 'Want some Sneeze cakes? Fresh out of the freezer!'",
            "'Sure, mom.' You respond back. She takes off to the kitchen to get the frozen blocks of ice. You grap Wiggles and set him down and Wiggles returns to looking out of the window of your bedroom, like he always does. You yawn and rub your eyes. You then wonder why he looks out the window. Maybe he is waiting for the love of his floating dog life to come back to steal his heart again... but maybe he is waiting for PB and Octopus sandwhiches supply car to come and run over your street again."
        ],
        new Array<SectionAction>(
            new SectionAction("Think: He is waiting for the love of his floating dog life to come back to steal his heart again.", "thinkLoveOfLifeForFloatingDog"),
            new SectionAction("Think: He is waiting for the PB and Octopus supply car to come and run over your street again.", "thinkPuntingBunsandOctopusSandwhiches")
        )
    ),
    new Section(
        "sleptFantastic",
        "YOU SLEPT FANTASTICLY",
        [
            "'Fantastic,' you say confidently.", 
            "'Well, that's good,' She says.",
            "Suddenly a concussive boom rattles your ears, the house shakes and debris begins pelting you. You use your arms to shield your eyes from dust. The dust settles and you see a hot air ballon has just broken its way into your room from the outside.",
            "'Good Morning Gerald!', says mom excitedly."
        ],
        new Array<SectionAction>(
            new SectionAction("Shout: He Almost Killed Me!", "shoutKilledMe"),
            new SectionAction("Greet: Oh hey, what's up Gerald?", "greetGerald"),
            new SectionAction("Battle Mode: Bob VS Gerald", "battleGerald") 
        )
    ),
    new Section(
        "battleGerald",
        "BATTLE MODE ENGAGED",
        [],
        new Array<SectionAction>(
            new SectionAction("Shout: He Almost Killed Me!", "shoutKilledMe"),
        ),
        true,
        new Gerald()
    ),
    new Section(    
        "shoutKilledMe",
        "YOU SHOUTED 'HE ALMOST KILLED ME'",
        [
            "'HE ALMOST KILLED ME!' You yell, and point at your friend Gerald.", 
            "'That's not nice,' Mom says back. ",
            "Gerald and your mom give you a stern look. Gerald is not happy with your desicion. He whinnies amd neighs. (Yes, he is a horse.)",
            "'You hurt your friends's feelings, Bob!' Mom says.",
            "Just then the PB and Octopus truck that Wiggles loves to chase rams down the road next to your house. The tires are on fire and everywhere the truck goes, the fire leads. 'What in the world?' You say. Just then, Wiggles barks and jumps up. He steps back and runs up to the window. The force of his ram causes the window to break! 'Wiggles!' Mom yells. You just look at her. 'What are we going to do?' You say. Gerald looks at you. He loves your 'dog' too. But the PB and Octopus sandwhiches look so good! You want a PB and Octopus sandwhich now. So, do you go save the dog, or get a PB and Octopus sandwhich for free?",
        ],
        new Array<SectionAction>(
            new SectionAction("Action: Save the dog.", "actionToSaveTheDog"),
            new SectionAction("Action: Get a PB and Octopus sandwhich from the truck.", "getPuntingBalogaAndOctopusSandwhich")
            
        )
    ),
    new Section(
        "thinkLoveOfLifeForFloatingDog",
        "YOU THINK OF THE LOVE OF WIGGLE'S LIFE",
        [
            "You start to daydream about what a wonderful world it used to be for Wiggles. He had a pretty poochie. A famous one, too. She was the most wonderful and prettiest doggie in the whole Obidience school. But then your marvelous dream was interupted by Wiggles barking at the window. A garbage truck had just turned down the street. Now, this may seem normal. but let me tell you the details. The garbage held inside of the truck was on fire, and a bear was driving it. Then the bear looks at you.", 
            "Then, he just tips his hat and drives away.",
            "Then you hear the doorbell ring. You wonder if it is your friend, who is a horse, Gerald. Then mom yells from the kitchen, 'I'll get it!' You go downstaris to see what is going on down there. It definitly does not sound like Gerald. And it doesn't sound like that stupid bear either. Who is it?"
        ],
        new Array<SectionAction>(
            new SectionAction("Think: It's Gerald in disguise.", "spyOnGerald"),
            new SectionAction("Think: It's the stupid bear hiding his accent.", "stupidBear"),
            new SectionAction("Battle Mode: Bob VS Mysterious Man at the Door", "battleMysteriousMan")
        )
    ),

    new Section(
        "actionToSaveTheDog",
        "YOU HAVE CHOSEN TO SAVE WIGGLES",
        [
            "You jump out of the window and race down the street, while Wiggles floats and barks at the driver. Suddenly, a PB and Octopus sandwhich flies out of the garbage truck and smacks right on your face. You rip it off and let it go flying which automacticly flings onto Gerald's face. He yanks it off and lets it go flying onto your mother's face, and your mother let's it go flying to the next door neighbor, who was wondering what all the commotion was about.",
            "'Sorry!' You yell as you pass by the truck. You don't even realize that your feet are making you sharply turn to your favorite restraunt in the whole world, Slimey World.",
            "'Bob!' Your mother yells.",
            "'Wha-a-a-at?' You yell back.",
            "Too late. You ram into the wall, but it doesn't seem to have any effect on you, because you keep on running, even through the wall. Dust and debris pelts the customers inside the restruant, and a few of them yell agrresivly at you. But you ram through the other wall and keep on running. Almost out of breath, you see the garbage truck crashing over graves and flowers in the Town Cemetary. You dog, Wiggles, floats in mid air and snatches a bar on the garbage truck which is yanked off almost immediatly and Wiggles is sent to the ground.  "
        ],
        new Array<SectionAction>(
            new SectionAction("Action: Run into the road and risk being ingured by a speeding car.", "RoadToInguries"),
            new SectionAction("Action: Wait for safety and then save Wiggles.", "SafetyToWiggles")
        )
    )

];

const chapter1 = new Chapter(Sections);

export default chapter1;