import { CustomEventTypes } from "constants/custom-event-types";
import CharacterTypes from "constants/character-types";
import BobWeaponSet from "./weapons/weapon-set";
import ArmorSet from "./armor/armor-set";

class Character {

    protected _name: CharacterTypes;
    private _isImmortal: boolean;
    private _health: number;
    private _attackDamage: number;
    protected _techSkill: number;
    protected _mechanicSkill: number;
    protected _fightSkill: number;
    private _armor: ArmorSet;
    private _weapons: BobWeaponSet;
    private _deathEvent: Event;

    constructor(){
        this._name = CharacterTypes.Bob;
        this._isImmortal = false;
        this._health = 100;
        this._attackDamage = 15;
        this._techSkill = 0;
        this._mechanicSkill = 0;
        this._fightSkill = 0;
        this._deathEvent = new Event(CustomEventTypes.PlayerDeath);
        this._weapons = new BobWeaponSet();
        this._armor = new ArmorSet()
    }

    get name(): CharacterTypes {
        return this._name;
    }

    get health(): number {
        return this._health;
    }

    get attackDamage(): number {
        return this._attackDamage + this._weapons.damage;
    }

    get alive(): boolean {
        return this._health > 0;
    }

    die(): void {
        this._health = 0;
        window.dispatchEvent(this._deathEvent);
    }

    restore(): void {
        this._health = 100;
    }

    takeDamage(damageAmount: number): number {
        if(!this._isImmortal){
            const damage: number = damageAmount - this._armor.damageAbsorbed;
            this._health -= damage;
            if(this._health <= 0) {
                window.dispatchEvent(this._deathEvent);
            }
            console.log("Returning: ", damage)
            return damage
        }
        return 0;
    }

    attack(who: Character): number {
        if(this.alive){
            let damageAmount = this._attackDamage;
            damageAmount += this._weapons.damage;
            return who.takeDamage(damageAmount);
        }
        return 0;
    }
}

export default Character;