import Character from "classes/character";
import BillyBobImg from "assets/images/billy-bob.png";
import NinjaBobImg from "assets/images/ninja-bob.png";
import UncleBobImg from "assets/images/uncle-bob.png";
import NinjaBob from "classes/bobs/ninja-bob";
import UncleBob from "classes/bobs/uncle-bob";
import BillyBob from "classes/bobs/billy-bob";
import { UseUpdateBob, BobAction, BobActionType} from "contexts/bob-context";
import ComponentProps from "classes/component-props";
import "./bob-selector.scss"

export interface BobSelectorProps extends ComponentProps {
    onSelectBob?: Function,
}

const BobSelector = (props: BobSelectorProps) => {

    const myNinjaBob = new NinjaBob();
    const myUncleBob = new UncleBob();
    const myBillyBob = new BillyBob();
    const updateBob = UseUpdateBob();

    const selectBob = (selectedBob: Character) => {
        updateBob(new BobAction(BobActionType.SetBob, selectedBob));
        if(props.onSelectBob){
            props.onSelectBob(selectedBob)
        }
    }

    return (
        <div className={`bobSelectorContainer ${props.className}`}>
            <div className="bob">
                <img src={NinjaBobImg} alt="Ninja Bob" />
                <button onClick={() => selectBob(myNinjaBob)}>Ninja Bob</button>
            </div>
            <div className="bob">
                <img src={UncleBobImg} alt="Uncle Bob" />
                <button onClick={() => selectBob(myUncleBob)}>Uncle Bob</button>
            </div>
            <div className="bob">
                <img src={BillyBobImg} alt="Billy Bob" />
                <button onClick={() => selectBob(myBillyBob)}>Billy Bob</button>
            </div>
        </div>
    )
}

export default BobSelector;