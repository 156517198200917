import Weapon from "classes/weapons/weapon";

export default class WeaponSet {

    main: Weapon | undefined;
    offHand: Weapon | undefined;
    both: Weapon | undefined;

    get damage(): number {
        let damage: number = 0;
        if(this.main !== undefined){
            damage += this.main.damage;
        }
        if(this.offHand !== undefined){
            damage += this.offHand.damage;
        }
        if(this.both !== undefined){
            damage += this.both.damage;
        }
        return damage;
    }
}