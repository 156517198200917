import { UseBob } from "contexts/bob-context";
import { Section } from "classes/chapter";
import ComponentProps from "classes/component-props";
import "./story-frame.scss"

export interface StoryFrameProps extends ComponentProps {
    section: Section;
    onAction: Function;
}

const StoryFrame = (props: StoryFrameProps) => {

    const {section, onAction} = props;
    const bob = UseBob();

    return (
        <div className={`storyFrameContainer ${props.className}`}>
            <div className="storySectionHeader">
                {section.title}
            </div>
            
            <div className="storySectionText">
                {section.paragraphs.map((paragraph, index) => <p key={`paragraph-${index}`}>{paragraph}</p>)}
            </div>

            <div className="storySectionActions">
                {section.actions.map((action, index) => <button key={`action-${index}`}onClick={() => onAction(action.sectionLink)}>{action.label}</button>)}
            </div>
                
        </div>
    )
}

export default StoryFrame;

