import Character from "../character";
import CharacterTypes from "constants/character-types";

class NinjaBob extends Character {

    constructor(){
        super();
        this._name = CharacterTypes.NinjaBob;
        this._fightSkill = 100;
    }

}

export default NinjaBob;