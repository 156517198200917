import Armor from "classes/armor/armor";

export default class ArmorSet {

    head: Armor | undefined;
    torso: Armor | undefined;
    legs: Armor | undefined;
    feet: Armor | undefined;

    get damageAbsorbed(): number {
        let absorbed: number = 0;
        if(this.head !== undefined){
            absorbed += this.head.damagePreventionRating;
        }
        if(this.torso !== undefined){
            absorbed += this.torso.damagePreventionRating;
        }
        if(this.legs !== undefined){
            absorbed += this.legs.damagePreventionRating;
        }
        if(this.feet !== undefined){
            absorbed += this.feet.damagePreventionRating;
        }
        return absorbed;
    }

}