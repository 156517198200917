import {useState} from 'react';
import ComponentProps from 'classes/component-props';
import Chapter, { Section } from "classes/chapter"
import StoryFrame from 'components/story-frame/story-frame';
import CharacterHUD from 'components/character-hud/character-hud';
import { UseBob } from 'contexts/bob-context';
import "./chapter-navigator.scss";
import Battle from 'components/battle/battle';

export interface StoryNavigatorProps extends ComponentProps {
    chapter: Chapter
}

const ChapterNavigator = (props: StoryNavigatorProps) => {

    const { chapter } = props;
    const [currentSection, setCurrentSection] = useState<Section>(chapter.startSection);
    const bob = UseBob();

    const goToSection = (sectionId: string) => {
        let newActiveSection = chapter.goToSection(sectionId);
        setCurrentSection(newActiveSection);
    }   

    const onBattleResult = () => {
        let newActiveSection = chapter.goToSection(currentSection.actions[0].sectionLink);
        setCurrentSection(newActiveSection);
    }

    return (
        <div className={`chapterNavigatorContainer ${props.className}`}>
            {currentSection.isBattle ?
                <>
                    {currentSection.who &&
                        <Battle who={currentSection.who} onResult={onBattleResult}/>
                    }
                </>
            :
                <>
                    <StoryFrame section={currentSection} onAction={goToSection}/>
                    <CharacterHUD character={bob}/>
                </>
            }
        </div>
    );
}

export default ChapterNavigator;