import {
  BrowserRouter as Router,
  Routes as Switch,
  Route,
} from "react-router-dom";
import { UseBob } from "contexts/bob-context";
import BobSelector from "components/bob-selector/bob-selector";
import CharacterTypes from "constants/character-types";
import Story from "../pages/story/story";


function Routes() {

    const bob = UseBob();

    return (
        <Router basename="/">
            {bob.name === CharacterTypes.Bob ?
                <BobSelector/>
            :

                <Switch>
                    <Route path="/" element={<Story />}/>
                </Switch>
            }
        </Router>
    );
}
  
export default Routes