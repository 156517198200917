import Character from "../character";
import CharacterTypes from "constants/character-types";

class BillyBob extends Character {

    constructor(){
        super();
        this._name = CharacterTypes.BillyBob;
        this._mechanicSkill = 100;
    }

}

export default BillyBob;