import Character from "classes/character";
import { Exception } from "sass";

export default class Chapter {

    private sections: Section[];
    private _activeSection?: Section;

    constructor(sections: Section[]){
        this.sections = sections;
        if(sections.length > 0){
            this._activeSection = sections[0];
        }
    }

    goToSection(sectionId: string): Section {
        const section = this.sections.find(section => section.id === sectionId);
        if(section){
            this._activeSection = section;
            return section;
        }
        throw new Error("Invalid Section Id");
    }

    get startSection(): Section {
        return this.sections[0];
    }

    get activeSection(): Section | undefined {
        return this._activeSection;
    }

}

export class Section {

    id: string;
    title: string;
    paragraphs: string[];
    actions: SectionAction[];
    isBattle: boolean;
    who: Character | null;

    constructor(id: string, title: string, paragraphs: string[], actions: SectionAction[], isBattle: boolean = false, who: Character | null = null) {
        this.id = id;
        this.title = title;
        this.paragraphs = paragraphs;
        this.actions = actions;
        this.isBattle = isBattle;
        this.who = who;
    }

}

export class SectionAction {

    label: string;
    sectionLink: string;
    
    constructor(label: string, sectionLink: string){
        this.label = label;
        this.sectionLink = sectionLink;
    }

}